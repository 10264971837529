*, :after, :before {
  box-sizing: inherit;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, hr {
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main, form legend {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

button, input, textarea, select {
  margin: 0;
}

input {
  color: inherit;
  line-height: inherit;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
}

select.form-control::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
  vertical-align: top;
  overflow: auto;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  max-width: 100%;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

:root {
  --color-accent: #ee1c25;
  --color-accent-light: #df9a8b;
  --color-grey: #e2e2e2;
  --color-light-grey: #ededed;
  --color-dark-grey: #666;
  --color-text-light-grey: #afafaf;
  --color-text-grey: gray;
  --color-text-black: #1a1a1a;
  --color-black: #000;
  --ease-in-out: cubic-bezier(.645, .045, .355, 1);
  --ease-in: cubic-bezier(.55, .055, .675, .19);
  --ease-out: cubic-bezier(.215, .61, .355, 1);
  --ease-out-back: cubic-bezier(.34, 1.56, .64, 1);
  --shadow: rgba(255, 255, 255, .075) 0px 0px .5px 1px inset, rgba(0, 0, 0, .03) 0px .9px 1.5px 0px, rgba(0, 0, 0, .08) 0px 3.1px 5.5px 0px, rgba(0, 0, 0, .12) 0px 14px 25px 0px;
  --shadow-light: rgba(255, 255, 255, .075) 0px 0px .5px 1px inset, rgba(0, 0, 0, .024) 0px .3px .4px 0px, rgba(0, 0, 0, .05) 0px .9px 1.5px 0px, rgba(0, 0, 0, .1) 0px 3.5px 6px 0px;
  --shadow-sm: 0 .3px .4px rgba(0, 0, 0, .024), 0 .9px 1.5px rgba(0, 0, 0, .05), 0 3.5px 6px rgba(0, 0, 0, .1);
  --component-padding: 2rem;
  --item-preview-padding: .2rem;
  --space-unit: 1rem;
  --space-2xs: calc(var(--space-unit) * .5625);
  --space-xxs: .5625rem;
  --space-xs: .75rem;
  --space-sm: 1.125rem;
  --space-md: 2rem;
  --space-lg: calc(var(--space-unit) * 3);
  --space-xl: calc(var(--space-unit) * 4.875);
  --space-2xl: calc(var(--space-unit) * 7.875);
  --space-3xl: calc(var(--space-unit) * 12.75);
  --space-4xl: calc(var(--space-unit) * 20.625);
  --radius: .375em;
  --radius-lg: calc(var(--radius, .25em) * 2);
  --radius-md: var(--radius, .25em);
  --max-width-xxxxs: 20rem;
  --max-width-xxxs: 26rem;
  --max-width-xxs: 32rem;
  --max-width-xs: 38rem;
  --max-width-sm: 48rem;
  --max-width-md: 64rem;
  --max-width-lg: 80rem;
  --max-width-xl: 90rem;
  --max-width-xxl: 100rem;
  --max-width-xxxl: 120rem;
  --max-width-xxxxl: 150rem;
  --modal-transition-duration: .2s;
  --custom-checkbox-size: 20px;
  --custom-checkbox-radius: 0;
  --custom-checkbox-border-width: 1px;
  --custom-checkbox-marker-size: 18px;
  --page-bg: var(--color-grey);
  --page-color: var(--color-text-black);
  --header-color: var(--color-text-grey);
  --header-color-bg: var(--color-light-grey);
  --side-nav-color: #1a1a1a;
}

[data-theme="dark"] {
  --header-color-bg: #000;
  --page-color: #fff;
  --page-bg: var(--color-text-black);
  --side-nav-color: #939393;
}

@font-face {
  font-family: Futura;
  src: url("futura-bold.928ba01e.woff2") format("woff2");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: Futura;
  src: url("Futura-Dem.a4727e9a.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Futura;
  src: url("Futura-Med.0be77cdc.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: Futura;
  src: url("futura-book.207248c4.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

html.cursor-pointer {
  cursor: pointer;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  background-color: var(--page-bg);
  color: var(--page-color);
  font-family: Futura;
  font-weight: 400;
  transition: background-color .3s, color .3s;
}

::selection {
  background: var(--color-accent-light);
}

button {
  color: inherit;
  font-family: Futura;
  font-weight: 700;
  line-height: inherit;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 0;
  padding: 0;
}

.container {
  width: calc(100% - 2 * var(--component-padding));
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.max-sm {
  max-width: 768px;
}

.max-md {
  max-width: 1024px;
}

.max-lg {
  max-width: 1200px;
}

.sr-only {
  clip-path: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  border: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.icon {
  color: inherit;
  fill: currentColor;
  height: 1em;
  width: 1em;
  max-width: initial;
  flex-shrink: 0;
  line-height: 1;
  display: block;
}

img {
  width: 100%;
  height: auto;
  position: relative;
}

.loadImg {
  opacity: 0;
  transition: opacity .3s;
}

.loadImg.loaded {
  opacity: 1;
}

.loading:before, .loading:after {
  content: "";
  z-index: 9999;
  cursor: none;
  position: fixed;
}

.loading:before {
  width: 100%;
  height: 100%;
  background: var(--color-grey);
  top: 0;
  left: 0;
}

.loading:after {
  width: 100px;
  height: 100px;
  pointer-events: none;
  opacity: .4;
  background: var(--color-accent);
  border-radius: 50%;
  margin: -50px 0 0 -50px;
  -webkit-animation: .7s linear infinite alternate forwards loaderAnim;
  animation: .7s linear infinite alternate forwards loaderAnim;
  top: 50%;
  left: 50%;
}

@-webkit-keyframes loaderAnim {
  to {
    opacity: .7;
    -webkit-transform: scale3d(.5, .5, 1);
    transform: scale3d(.5, .5, 1);
  }
}

@keyframes loaderAnim {
  to {
    opacity: .7;
    -webkit-transform: scale3d(.5, .5, 1);
    transform: scale3d(.5, .5, 1);
  }
}

.custom-checkbox {
  z-index: 1;
  font-size: var(--custom-checkbox-size);
  display: inline-block;
  position: relative;
}

.custom-checkbox__input {
  opacity: 0;
  height: var(--custom-checkbox-size);
  width: var(--custom-checkbox-size);
  z-index: 1;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

.custom-checkbox__control {
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  transition: -webkit-transform .2s, transform .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-checkbox__control:before, .custom-checkbox__control:after {
  content: "";
  position: absolute;
}

.custom-checkbox__control:before {
  width: 160%;
  height: 160%;
  background-color: var(--color-accent);
  opacity: 0;
  will-change: transform;
  border-radius: 50%;
  transition: -webkit-transform .2s, transform .2s;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%)scale(0);
  transform: translate(-50%, -50%)scale(0);
}

.custom-checkbox__control:after {
  width: 100%;
  height: 100%;
  border-radius: var(--custom-checkbox-radius);
  box-shadow: inset 0 0 0 var(--custom-checkbox-border-width) currentColor, var(--shadow-xs);
  background-color: #000;
  top: 0;
  left: 0;
}

.custom-checkbox__input:checked ~ .custom-checkbox__control:after, .custom-checkbox__input:indeterminate ~ .custom-checkbox__control:after {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--custom-checkbox-marker-size);
  box-shadow: none;
}

.custom-checkbox__input:checked ~ .custom-checkbox__control {
  color: var(--color-accent);
}

.custom-checkbox__input:checked ~ .custom-checkbox__control:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpolyline points='2.5 8 6.5 12 13.5 3' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
}

.custom-checkbox__input:indeterminate ~ .custom-checkbox__control:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cline x1='2' y1='8' x2='14' y2='8' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
}

.custom-checkbox__input:active ~ .custom-checkbox__control {
  -webkit-transform: scale(.9);
  transform: scale(.9);
}

.custom-checkbox__input:checked:active ~ .custom-checkbox__control, .custom-checkbox__input:indeterminate:active ~ .custom-checkbox__control {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.custom-checkbox__input:focus-visible ~ .custom-checkbox__control:before {
  opacity: .2;
  -webkit-transform: translate(-50%, -50%)scale(1);
  transform: translate(-50%, -50%)scale(1);
}

h1 {
  font-size: 2vw;
  line-height: 1em;
}

h2 {
  font-size: 9.72vw;
  font-weight: 600;
  line-height: 1em;
}

@media (min-width: 64rem) {
  h2 {
    font-size: 5.4vw;
  }
}

h3 {
  letter-spacing: .1em;
  font-size: 4.5vw;
  font-weight: 500;
  line-height: 1em;
}

@media (min-width: 64rem) {
  h3 {
    font-size: 2.5vw;
  }
}

h4, h5 {
  text-transform: uppercase;
  font-size: 2.25vw;
  font-weight: 600;
  line-height: 1em;
}

@media (min-width: 64rem) {
  h4, h5 {
    font-size: .9vw;
  }
}

h5 {
  letter-spacing: .08em;
}

p {
  font-size: 5vw;
  font-weight: 500;
}

@media (min-width: 64rem) {
  p {
    font-size: 2vw;
  }
}

.xs {
  font-size: 1.625vw;
  font-weight: 400;
  line-height: 1.2em;
}

@media (min-width: 64rem) {
  .xs {
    font-size: .65vw;
  }
}

.book {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.inlineblock {
  display: inline-block;
}

h1, h2, h3 {
  text-transform: uppercase;
}

.callToaction {
  background-color: var(--color-accent);
  text-transform: uppercase;
  text-align: center;
  padding: var(--space-2xs) var(--space-sm);
  color: #fff;
  letter-spacing: .05em;
  font-size: 2vw;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  transition: box-shadow .2s, background-color .3s, color .3s;
  display: inline-flex;
}

@media (min-width: 64rem) {
  .callToaction {
    font-size: .8vw;
  }
}

.callToaction:hover, .callToaction:focus-visible {
  color: var(--color-accent);
  background-color: #fff;
}

.textref {
  color: #939393;
  font-weight: 500;
  line-height: 1em;
  text-decoration: none;
  transition: color .2s;
  display: inline-flex;
  position: relative;
}

.textref .icon {
  stroke: #939393;
  transition: stroke .2s;
  -webkit-animation: 2s infinite arrow-pulse;
  animation: 2s infinite arrow-pulse;
}

.textref span {
  display: inline-flex;
  position: relative;
}

.textref span:after {
  content: "";
  width: 100%;
  height: .1em;
  background-color: #939393;
  transition: background-color .1s, height .2s;
  position: absolute;
  bottom: -.2em;
  left: 0;
}

.textref:hover, .textref:focus-visible {
  color: #fff;
}

.textref:hover .icon, .textref:focus-visible .icon {
  stroke: #fff;
}

.textref:hover span:after, .textref:focus-visible span:after {
  background-color: #fff;
}

.v-hidden {
  visibility: hidden;
}

.inlineblock {
  display: inline-block;
}

canvas {
  display: block;
}

.canvas {
  z-index: 0;
  display: block;
  position: relative;
}

.canvas__buttons {
  z-index: 1;
  padding: var(--space-xs);
  position: absolute;
  bottom: 0;
  left: 0;
}

.canvas__btn {
  background-color: var(--color-accent);
  cursor: pointer;
  padding: var(--space-xxs) var(--space-xs);
  border-radius: var(--radius-md);
  white-space: nowrap;
  color: #fff;
  border: none;
  justify-content: center;
  line-height: 1.2;
  display: inline-flex;
}

.canvas__btn.active {
  background-color: var(--color-accent-light);
}

.svg-fill-grey {
  fill: #939393;
}

.svg-stroke-grey {
  stroke: #939393;
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--header-color-bg);
  height: calc(1vw + var(--space-sm)  + 1.5vw);
  transition: background-color .3s;
  position: fixed;
  bottom: 0;
  left: 0;
}

.header__content {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.header__content .box {
  height: 10px;
  margin-right: .5em;
  display: inline-block;
}

@media (min-width: 64rem) {
  .header__content .box {
    height: 15px;
    margin-right: 1em;
  }
}

.header__content .box svg {
  height: 100%;
  display: block;
  width: auto !important;
}

.header__content svg {
  height: 100%;
  width: auto;
}

.header__content span {
  color: var(--header-color);
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  line-height: 1em;
  display: inline-block;
}

@media (min-width: 64rem) {
  .header__content span {
    font-size: 20px;
  }
}

.header__content .callToaction {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

@media (min-width: 64rem) {
  .header__content .callToaction {
    display: block;
  }
}

.side {
  z-index: 10;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
}

@media (min-width: 48rem) {
  .side {
    display: block;
  }
}

.side__list {
  padding: var(--space-md);
}

.side__list li {
  margin-bottom: var(--space-xxs);
  text-align: right;
}

.side__list li span {
  display: inline-block;
}

.side__link {
  text-transform: uppercase;
  color: #939393;
  padding-right: 1.2vw;
  font-size: 1vw;
  font-weight: 600;
  text-decoration: none;
  position: relative;
}

.side__link.slide__link--selected:after {
  background-color: #939393;
}

.side__link:before, .side__link:after {
  content: "";
  position: absolute;
}

.side__link:before {
  width: 0;
  height: 2px;
  background-color: #939393;
  transition: width .3s;
  bottom: 0;
  left: 0;
}

.side__link:after {
  width: .8vw;
  height: .8vw;
  background-color: none;
  border: 2px solid #939393;
  border-radius: 50%;
  transition: background-color .3s;
  top: 0;
  right: 0;
}

.side__link:hover:before {
  width: calc(100% - 1.2vw);
}

.side__link:hover:after {
  background-color: #939393;
}

.pc {
  position: relative;
  overflow-x: hidden;
}

.pc__seq {
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.pc__seqa-canvas, .pc__seqb-canvas {
  background: var(--color-text-black);
}

.pc__seqa-canvas, .pc__seqb-canvas, .pc__seqh-canvas {
  z-index: -1;
  opacity: 1;
  transition: opacity 1s var(--ease-out);
  position: absolute;
  top: 0;
  left: 0;
}

.password {
  width: 100%;
  height: 100vh;
  z-index: 100;
  color: #fff;
  background-color: #1a1a1a;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.password__content {
  width: 100%;
  max-width: 520px;
  position: relative;
}

.password__content h3 {
  margin-bottom: var(--space-sm);
}

.password__content #password-request-feedback {
  color: var(--color-accent);
  font-weight: 700;
}

.password__form {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.password__form input {
  width: 100%;
  padding: var(--space-2xs);
  flex-basis: 75%;
  font-size: 1.5vw;
  line-height: 1em;
  position: relative;
}

.grid {
  grid-template-columns: repeat(12, 1fr);
  display: grid;
}

.custom-checkbox:first-child {
  margin-right: 5px;
  font-size: 6vw;
}

@media (min-width: 64rem) {
  .custom-checkbox:first-child {
    font-size: 1.5vw;
  }
}

.custom-checkbox .custom-checkbox__input {
  width: 6vw;
  height: 6vw;
}

@media (min-width: 64rem) {
  .custom-checkbox .custom-checkbox__input {
    width: 1.5vw;
    height: 1.5vw;
  }
}

.checkbox-grey .custom-checkbox__control:after {
  background-color: #c5c5c5;
}

.checkbox-grey .custom-checkbox__input:checked ~ .custom-checkbox__control {
  color: #c5c5c5;
}

.checkbox-black .custom-checkbox__input:checked ~ .custom-checkbox__control {
  color: var(--color-text-black);
}

.viewport-overlay {
  padding: var(--space-lg) var(--space-md);
  width: 100%;
  height: 100vh;
  background: var(--color-grey);
  z-index: 9998;
  visibility: hidden;
  opacity: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.viewport-overlay.landscape {
  visibility: visible;
  opacity: 1;
}

.viewport-overlay h3 {
  color: var(--color-accent);
  text-align: center;
}

.pc__seqa, .pc__seqb {
  width: 100%;
  height: calc(100vh - (1vw + var(--space-sm)  + 1.5vw));
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.pc__seqa h3, .pc__seqb h3 {
  margin-top: var(--space-md);
}

@media (min-width: 64rem) {
  .pc__seqa h3, .pc__seqb h3 {
    margin-top: var(--space-sm);
  }
}

.pc__seqa .icon {
  margin-bottom: var(--space-md);
  height: 3.6vw;
  width: auto;
  color: var(--color-text-black);
}

@media (min-width: 64rem) {
  .pc__seqa .icon {
    height: 2vw;
    margin-bottom: var(--space-lg);
  }
}

.pc__seqb {
  color: #fff;
}

.pc__seqc {
  width: 100%;
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.pc__seqc h2, .pc__seqc h3 {
  color: #fff;
}

.pc__seqc h3 {
  margin-bottom: var(--space-sm);
}

@media (min-width: 64rem) {
  .pc__seqc h3 {
    margin-bottom: var(--space-md);
  }

  .pc__seqc-h2-wrapper {
    -webkit-transform: translateX(3em);
    transform: translateX(3em);
  }
}

.pc__seqc-span-container {
  flex-direction: column;
  display: inline-flex;
  position: relative;
}

.pc__seqc-t-c, .pc__seqc-t-d, .pc__seqc-t-e {
  width: auto;
}

@media (min-width: 64rem) {
  .pc__seqc-t-c, .pc__seqc-t-d, .pc__seqc-t-e {
    display: inline-flex;
  }
}

.pc__seqc-t-c.ready span:after, .pc__seqc-t-d.ready span:after, .pc__seqc-t-e.ready span:after {
  width: 100%;
}

.pc__seqc-t-c span, .pc__seqc-t-d span, .pc__seqc-t-e span {
  display: inline-block;
  position: relative;
}

.pc__seqc-t-c span:after, .pc__seqc-t-d span:after, .pc__seqc-t-e span:after {
  content: "";
  height: .05em;
  width: 0;
  transition: width .5s var(--ease-out);
  background-color: #fff;
  position: absolute;
  bottom: -.05em;
  left: 0;
}

.pc__seqd {
  padding: var(--space-sm) var(--space-sm);
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 64rem) {
  .pc__seqd {
    padding: var(--space-lg) var(--space-md);
    display: block;
  }
}

.pc__seqd-content {
  text-align: center;
  width: 100%;
  margin-top: calc((1vw + var(--space-sm)  + 1.5vw) * -1);
  position: relative;
}

@media (min-width: 64rem) {
  .pc__seqd-content {
    margin-top: 0;
  }
}

.pc__seqd-content h2 {
  z-index: 2;
}

.pc__seqd-content .grid {
  padding-top: var(--space-sm);
}

@media (min-width: 64rem) {
  .pc__seqd-content .grid {
    padding-top: var(--space-md);
  }
}

.pc__seqd-image {
  padding-top: var(--space-md);
  margin-bottom: var(--space-sm);
  grid-column: 3 / 11;
  position: relative;
}

@media (min-width: 48rem) {
  .pc__seqd-image {
    grid-column: 3 / 11;
  }
}

.pc__seqd-details {
  text-align: left;
  grid-column: 1 / 13;
}

@media (min-width: 64rem) {
  .pc__seqd-details {
    grid-column: 7 / 13;
  }
}

.pc__seqd-details li {
  width: 100%;
  position: absolute;
}

@media (min-width: 64rem) {
  .pc__seqd-details li {
    margin-bottom: var(--space-sm);
    position: relative;
  }
}

.pc__seqd-details li p {
  text-align: center;
  color: #fff;
  transition: color .3s;
}

@media (min-width: 64rem) {
  .pc__seqd-details li p {
    color: var(--color-dark-grey);
    text-align: left;
  }
}

.pc__seqd-details li p.active-point {
  color: #fff;
}

.pc__seqd-canvas {
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.pc__seqe {
  padding: var(--space-lg) var(--space-md) 0 var(--space-md);
  position: relative;
}

.pc__seqe-content {
  text-align: center;
  width: 100%;
  position: relative;
}

.pc__seqe-content .grid {
  padding-top: var(--space-md);
  margin-left: calc(var(--space-md) * -1);
  margin-right: calc(var(--space-md) * -1);
}

@media (min-width: 64rem) {
  .pc__seqe-content .grid {
    margin-left: 0;
    margin-right: 0;
  }
}

.pc__seqe-image-mobile {
  order: 3;
  grid-column: 5 / 13;
  margin-top: -40%;
}

.pc__seqe-image {
  z-index: -1;
  padding-top: var(--space-lg);
  order: 2;
  grid-column: 1 / 10;
  position: relative;
}

@media (min-width: 64rem) {
  .pc__seqe-image {
    order: 1;
    grid-column: 3 / 6;
  }
}

.pc__seqe-image-container {
  width: 100%;
}

@media (min-width: 64rem) {
  .pc__seqe-image-container {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.pc__seqe-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pc__seqe-details {
  text-align: left;
  order: 1;
  grid-column: 1 / 13;
  position: relative;
}

@media (min-width: 64rem) {
  .pc__seqe-details {
    order: 2;
    grid-column: 7 / 13;
    padding-top: 0;
  }
}

.pc__seqe-details li {
  width: 100%;
  position: absolute;
}

@media (min-width: 64rem) {
  .pc__seqe-details li {
    margin-bottom: var(--space-sm);
    position: relative;
  }
}

.pc__seqe-details li p {
  text-align: center;
  color: #fff;
  transition: color .3s;
}

@media (min-width: 64rem) {
  .pc__seqe-details li p {
    color: var(--color-dark-grey);
    text-align: left;
  }
}

.pc__seqe-details li p.active-point {
  color: #fff;
}

.pc__seqe-canvas {
  grid-column: 5 / 9;
  justify-content: center;
  display: none;
  overflow: hidden;
}

@media (min-width: 64rem) {
  .pc__seqe-canvas {
    display: flex;
  }
}

.pc__seqf {
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.pc__seqf-video-container {
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .pc__seqf-video-container video {
    visibility: hidden;
  }

  .pc__seqf-video-container img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
  }
}

.pc__seqf-video-container video {
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  max-width: none;
  display: none;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%)translateY(-50%);
  transform: translateX(-50%)translateY(-50%);
}

@supports (object-fit: cover) {
  .pc__seqf-video-container video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

@supports (object-fit: cover) and (-ms-ime-align: auto) {
  .pc__seqf-video-container video {
    height: auto;
    width: auto;
  }
}

.pc__seqf-video-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
}

.pc__seqf-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pc__seqf-content {
  width: 100%;
  position: relative;
}

.pc__seqf-content h2, .pc__seqf-content p {
  text-align: center;
  margin-bottom: var(--space-lg);
}

@media (min-width: 64rem) {
  .pc__seqf-content p {
    max-width: 35vw;
    text-align: left;
    margin-bottom: calc(var(--space-lg) * 3);
  }
}

.pc__seqf-content .textref {
  text-align: center;
  font-size: 4vw;
}

@media (min-width: 64rem) {
  .pc__seqf-content .textref {
    font-size: 2vw;
  }
}

.pc__seqf-content .textref .icon {
  font-size: 4vw;
}

@media (min-width: 64rem) {
  .pc__seqf-content .textref .icon {
    font-size: 2vw;
  }

  .pc__seqf-content .textref span {
    margin-right: var(--space-xs);
  }
}

.pc__seqf-content .max-md {
  justify-content: flex-end;
  display: flex;
}

@-webkit-keyframes arrow-pulse {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes arrow-pulse {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.pc__seqg {
  padding: var(--space-2xl) var(--space-md) 0 var(--space-md);
  position: relative;
}

.pc__seqg-content {
  text-align: center;
  width: 100%;
  position: relative;
}

.pc__seqg-content .grid {
  padding-top: var(--space-md);
}

.pc__seqg-image-container {
  height: 0;
  margin-left: calc(var(--space-md) * -1);
  margin-right: calc(var(--space-md) * -1);
  order: 2;
  grid-column: 1 / 13;
  padding-bottom: 80%;
  position: relative;
}

@media (min-width: 64rem) {
  .pc__seqg-image-container {
    height: auto;
    order: 1;
    grid-column: 3 / 7;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
  }
}

.pc__seqg-image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: absolute;
  top: -20%;
  left: 0;
}

@media (min-width: 64rem) {
  .pc__seqg-image {
    top: -60%;
  }
}

.pc__seqg-details {
  text-align: left;
  margin-bottom: var(--space-lg);
  order: 1;
  grid-column: 1 / 13;
}

@media (min-width: 64rem) {
  .pc__seqg-details {
    order: 2;
    grid-column: 7 / 13;
  }
}

.pc__seqg-details li {
  width: 100%;
  position: absolute;
}

@media (min-width: 64rem) {
  .pc__seqg-details li {
    margin-bottom: var(--space-sm);
    position: relative;
  }
}

.pc__seqg-details li p {
  text-align: center;
  color: var(--color-text-black);
  transition: color .3s;
}

@media (min-width: 64rem) {
  .pc__seqg-details li p {
    color: var(--color-dark-grey);
    text-align: left;
  }
}

.pc__seqg-details li p.active-point {
  color: var(--color-text-black);
}

.pc__seqh-content {
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.pc__seqi {
  min-height: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.pc__seqi-degree {
  top: var(--component-padding);
  left: var(--component-padding);
  display: none;
  position: absolute;
}

@media (min-width: 64rem) {
  .pc__seqi-degree {
    display: block;
  }
}

.pc__seqi-degree .icon {
  width: 2.6vw;
  height: 2.6vw;
  color: var(--color-dark-grey);
  stroke: var(--color-dark-grey);
}

.pc__seqi-ctrl {
  left: 0;
  top: calc(var(--space-sm)  + 5vw);
  width: 100%;
  padding: var(--space-sm) var(--space-xs);
  justify-content: space-between;
  display: flex;
  position: absolute;
}

@media (min-width: 64rem) {
  .pc__seqi-ctrl {
    padding: var(--space-sm) var(--component-padding);
    top: auto;
    bottom: calc(1vw + var(--space-sm)  + 1.5vw);
  }
}

.pc__seqi-ctrl-return {
  flex-basis: 20%;
}

.pc__seqi-ctrl-return-btn:hover .icon {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.pc__seqi-ctrl-return-btn:hover .icon .svg-fill-grey {
  fill: var(--color-accent);
}

.pc__seqi-ctrl-return-btn:hover .icon .svg-stroke-grey {
  stroke: var(--color-accent);
}

.pc__seqi-ctrl-return-btn:focus-visible .icon {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.pc__seqi-ctrl-return-btn:focus-visible .icon .svg-fill-grey {
  fill: var(--color-accent);
}

.pc__seqi-ctrl-return-btn:focus-visible .icon .svg-stroke-grey {
  stroke: var(--color-accent);
}

.pc__seqi-ctrl-return-btn .icon {
  width: 6vw;
  height: 6vw;
  color: var(--color-dark-grey);
  stroke: var(--color-dark-grey);
  transition: color .2s, stroke .2s, -webkit-transform .2s, transform .2s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media (min-width: 64rem) {
  .pc__seqi-ctrl-return-btn .icon {
    width: 1.5vw;
    height: 1.5vw;
  }
}

.pc__seqi-ctrl-texture {
  text-align: center;
  flex-basis: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 64rem) {
  .pc__seqi-ctrl-texture {
    flex-grow: 0;
    flex-basis: 60%;
  }
}

.pc__seqi-ctrl-texture-checkboxes {
  line-height: 0;
}

.pc__seqi-ctrl-kanne {
  color: var(--color-text-grey);
  text-transform: uppercase;
  font-size: 6vw;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

@media (min-width: 64rem) {
  .pc__seqi-ctrl-kanne {
    font-size: 1.5vw;
  }
}

.pc__seqi-ctrl-kanne.active-kanne {
  color: var(--color-text-black);
}

.pc__seqi-ctrl-kanne:hover, .pc__seqi-ctrl-kanne:focus-visible {
  color: var(--color-accent);
}

.pc__seqi-ctrl-texture-content {
  margin-right: var(--space-md);
}

.pc__seqi-ctrl-details {
  align-items: center;
  display: none;
}

@media (min-width: 64rem) {
  .pc__seqi-ctrl-details {
    flex-basis: 20%;
    display: flex;
  }
}

.pc__seqi-ctrl-details span {
  color: var(--color-text-light-grey);
  letter-spacing: .05em;
  text-transform: uppercase;
  font-size: .8vw;
  font-weight: 500;
  display: block;
}

.pc__seqi-scroll-container {
  display: block;
}

@media (min-width: 64rem) {
  .pc__seqi-scroll-container {
    display: none;
  }
}

.pc__seqi-scroll {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.pc__seqi-scroll:first-of-type {
  top: var(--space-sm);
}

.pc__seqi-scroll:last-of-type {
  bottom: calc(1vw + var(--space-sm)  + 5vw);
}

.pc__seqi-scroll-btn .icon {
  height: 5vw;
  width: auto;
}

.pc__seqi-popup {
  width: 100%;
  max-width: 50%;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  overflow: hidden;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (min-width: 64rem) {
  .pc__seqi-popup {
    max-width: 18vw;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.pc__seqi-popup:not(.popup--is-visible) {
  pointer-events: none;
}

.pc__seqi-popup.popup--is-visible {
  opacity: 1;
  visibility: visible;
}

.pc__seqi-popup-content {
  padding: var(--space-xs);
  background: rgba(255, 255, 255, .6);
  position: relative;
  overflow: hidden;
}

.pc__seqi-popup-list-item {
  gap: var(--space-xxs);
  margin-bottom: var(--space-xs);
  align-items: center;
  display: flex;
}

@media (min-width: 64rem) {
  .pc__seqi-popup-list-item {
    gap: var(--space-sm);
  }
}

.pc__seqi-popup-list-item figure {
  flex-shrink: 0;
  flex-basis: 15%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pc__seqi-popup-list-item figure .icon {
  width: 5vw;
  height: 5vw;
}

@media (min-width: 64rem) {
  .pc__seqi-popup-list-item figure .icon {
    width: 2vw;
    height: 2vw;
  }
}

.pc__seqi-popup-list-item figure #icon-timer-display {
  height: 2vw;
  width: auto;
}

@media (min-width: 64rem) {
  .pc__seqi-popup-list-item figure #icon-timer-display {
    height: .8vw;
    margin-top: -.2em;
  }
}

.pc__seqi-popup-list-item figure #icon-schalter {
  width: 5vw;
  height: 5vw;
}

@media (min-width: 64rem) {
  .pc__seqi-popup-list-item figure #icon-schalter {
    width: 2vw;
    height: 2vw;
  }
}

.pc__seqi-popup-list-item figure #icon-tassenzahl {
  width: auto;
  height: 4.2vw;
}

@media (min-width: 64rem) {
  .pc__seqi-popup-list-item figure #icon-tassenzahl {
    height: 1.4vw;
  }
}

.pc__seqi-popup-list-item figure #icon-timer {
  height: 4.8vw;
  width: auto;
}

@media (min-width: 64rem) {
  .pc__seqi-popup-list-item figure #icon-timer {
    height: 1.6vw;
  }
}

.pc__seqi-popup-list-item figure #icon-wasser {
  width: auto;
  height: 4.8vw;
}

@media (min-width: 64rem) {
  .pc__seqi-popup-list-item figure #icon-wasser {
    height: 1.6vw;
  }
}

.pc__seqi-popup-list-item figure #icon-kalk {
  width: auto;
  height: 4.8vw;
}

@media (min-width: 64rem) {
  .pc__seqi-popup-list-item figure #icon-kalk {
    height: 1.6vw;
  }
}

.pc__seqi-popup-list-item-details {
  color: var(--color-dark-grey);
}

.pc__seqi-overlay {
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: var(--page-bg);
  position: absolute;
  top: 0;
  left: 0;
}

.pc__seqi-overlay-container {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.pc__seqi-overlay-loader {
  width: 100px;
  height: 100px;
  pointer-events: none;
  opacity: .4;
  background: var(--color-accent);
  border-radius: 50%;
  -webkit-animation: .7s linear infinite alternate forwards loaderAnim;
  animation: .7s linear infinite alternate forwards loaderAnim;
}

.pc__seqj {
  position: relative;
}

.pc__seqj .heading-abs {
  top: var(--space-lg);
  width: 100%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.pc__seqj .heading-abs h3 {
  text-align: center;
  font-size: 7.5vw;
  font-weight: 600;
}

@media (min-width: 64rem) {
  .pc__seqj .heading-abs h3 {
    font-size: 2.5vw;
  }
}

.slideshow {
  width: 100%;
  max-width: 1800px;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.slideshow__container {
  width: 100%;
  height: 350px;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  margin: 0 auto;
  position: relative;
}

@media (min-width: 48rem) {
  .slideshow__container {
    width: 450px;
    height: 450px;
  }
}

@media (min-width: 64rem) {
  .slideshow__container {
    width: 18vw;
    height: 18vw;
    -webkit-perspective: 850px;
    perspective: 850px;
  }
}

.slideshow__content {
  height: 100%;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: -webkit-transform 1s, transform 1s;
  position: absolute;
}

.slideshow__item-content {
  width: 100%;
  text-align: center;
  opacity: .5;
  pointer-events: none;
  transition: -webkit-transform 1s, transform 1s, opacity 1s;
  display: block;
  position: absolute;
}

@media (min-width: 48rem) {
  .slideshow__item-content {
    width: 450px;
  }
}

@media (min-width: 64rem) {
  .slideshow__item-content {
    width: 18vw;
  }
}

.slideshow__item-content.active-slide {
  opacity: 1;
  pointer-events: auto;
}

.slideshow__item-content.back-slide {
  opacity: 0;
}

.slideshow__item-image {
  padding: var(--space-xs);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.slideshow__item-image img {
  -webkit-user-select: none;
  user-select: none;
  -moz-user-focus: none;
}

.slideshow__item-image:hover .callToaction, .slideshow__item-image:focus-visible .callToaction {
  background-color: var(--color-accent);
  color: #fff;
  border-color: var(--color-accent);
}

.slideshow__item-image .callToaction {
  margin-top: var(--space-sm);
  color: #a9a7a5;
  transition: box-shadow .2s, background-color .3s, color .3s border-color .3s;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #a9a7a5;
  line-height: 1em;
}

.slideshow__item.slideshow__item-0 {
  -webkit-transform: rotateY(0)translateZ(250px);
  transform: rotateY(0)translateZ(250px);
}

@media (min-width: 48rem) {
  .slideshow__item.slideshow__item-0 {
    -webkit-transform: rotateY(0)translateZ(350px);
    transform: rotateY(0)translateZ(350px);
  }
}

@media (min-width: 80rem) {
  .slideshow__item.slideshow__item-0 {
    -webkit-transform: rotateY(0)translateZ(18vw);
    transform: rotateY(0)translateZ(18vw);
  }
}

.slideshow__item.slideshow__item-1 {
  -webkit-transform: rotateY(90deg)translateZ(250px)rotateY(-90deg);
  transform: rotateY(90deg)translateZ(250px)rotateY(-90deg);
}

@media (min-width: 48rem) {
  .slideshow__item.slideshow__item-1 {
    -webkit-transform: rotateY(90deg)translateZ(350px)rotateY(-90deg);
    transform: rotateY(90deg)translateZ(350px)rotateY(-90deg);
  }
}

@media (min-width: 64rem) {
  .slideshow__item.slideshow__item-1 {
    -webkit-transform: rotateY(90deg)translateZ(18vw)rotateY(-90deg);
    transform: rotateY(90deg)translateZ(18vw)rotateY(-90deg);
  }
}

.slideshow__item.slideshow__item-2 {
  -webkit-transform: rotateY(180deg)translateZ(250px)rotateY(-180deg);
  transform: rotateY(180deg)translateZ(250px)rotateY(-180deg);
}

@media (min-width: 48rem) {
  .slideshow__item.slideshow__item-2 {
    -webkit-transform: rotateY(180deg)translateZ(350px)rotateY(-180deg);
    transform: rotateY(180deg)translateZ(350px)rotateY(-180deg);
  }
}

@media (min-width: 64rem) {
  .slideshow__item.slideshow__item-2 {
    -webkit-transform: rotateY(180deg)translateZ(18vw)rotateY(-180deg);
    transform: rotateY(180deg)translateZ(18vw)rotateY(-180deg);
  }
}

.slideshow__item.slideshow__item-3 {
  -webkit-transform: rotateY(270deg)translateZ(250px)rotateY(-270deg);
  transform: rotateY(270deg)translateZ(250px)rotateY(-270deg);
}

@media (min-width: 48rem) {
  .slideshow__item.slideshow__item-3 {
    -webkit-transform: rotateY(270deg)translateZ(350px)rotateY(-270deg);
    transform: rotateY(270deg)translateZ(350px)rotateY(-270deg);
  }
}

@media (min-width: 64rem) {
  .slideshow__item.slideshow__item-3 {
    -webkit-transform: rotateY(270deg)translateZ(18vw)rotateY(-270deg);
    transform: rotateY(270deg)translateZ(18vw)rotateY(-270deg);
  }
}

.slideshow__control, .slideshow__control-modal {
  z-index: 4;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.slideshow__control:first-of-type, .slideshow__control-modal:first-of-type {
  left: var(--space-sm);
}

@media (min-width: 64rem) {
  .slideshow__control:first-of-type, .slideshow__control-modal:first-of-type {
    left: var(--space-lg);
  }
}

.slideshow__control:first-of-type .slideshow__btn .icon, .slideshow__control-modal:first-of-type .slideshow__btn .icon {
  margin-right: .1em;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.slideshow__control:last-of-type, .slideshow__control-modal:last-of-type {
  right: var(--space-sm);
}

@media (min-width: 64rem) {
  .slideshow__control:last-of-type, .slideshow__control-modal:last-of-type {
    right: var(--space-lg);
  }
}

.slideshow__control:last-of-type .slideshow__btn .icon, .slideshow__control-modal:last-of-type .slideshow__btn .icon {
  margin-left: .1em;
}

.slideshow__control-modal {
  z-index: 4;
  position: absolute;
  top: 100%;
  -webkit-transform: translateY(-200%);
  transform: translateY(-200%);
}

@media (min-width: 48rem) {
  .slideshow__control-modal {
    -webkit-transform: translateY(-110%);
    transform: translateY(-110%);
  }
}

@media (min-width: 64rem) {
  .slideshow__control-modal {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.slideshow__btn {
  width: 12.25vw;
  height: 12.25vw;
  cursor: pointer;
  background: #a9a7a5;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: background-color .3s;
  display: flex;
}

@media (min-width: 64rem) {
  .slideshow__btn {
    width: 3.5vw;
    height: 3.5vw;
  }
}

.slideshow__btn:hover, .slideshow__btn:focus-visible {
  background-color: var(--color-accent);
  color: var(--color-accent);
}

.slideshow__btn .icon {
  color: #fff;
  font-size: 5.25vw;
}

@media (min-width: 64rem) {
  .slideshow__btn .icon {
    font-size: 1.5vw;
  }
}

.slideshow__content li {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.pc__seqk {
  padding: var(--space-md) var(--space-sm);
  justify-content: center;
  display: flex;
  position: relative;
}

@media (min-width: 64rem) {
  .pc__seqk {
    padding: var(--space-lg) var(--space-md);
  }
}

.pc__seqk-content {
  width: 100%;
  text-align: center;
  position: relative;
}

.pc__seqk-content .icon {
  height: 4vw;
  width: auto;
  margin: 0 auto var(--space-sm) auto;
}

@media (min-width: 64rem) {
  .pc__seqk-content .icon {
    height: 2vw;
    margin: 0 auto var(--space-lg) auto;
  }
}

.pc__seqk-grid {
  max-width: 1800px;
  column-gap: 1vw;
  row-gap: var(--space-sm);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(1vw + var(--space-sm)  + 1.5vw);
  padding-top: var(--space-lg);
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 64rem) {
  .pc__seqk-grid {
    padding-top: calc(var(--space-lg) * 2);
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 80rem) {
  .pc__seqk-grid {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 0;
  }
}

.pc__seqk-grid-col {
  text-align: left;
  padding: var(--space-sm);
  background: #fff;
  position: relative;
}

.pc__seqk-grid-header .icon {
  height: 2.275vw;
  width: auto;
  margin: 0;
}

@media (min-width: 64rem) {
  .pc__seqk-grid-header .icon {
    height: .65vw;
  }
}

.pc__seqk-grid-header h4 {
  padding-top: calc(var(--space-unit) * .5);
  font-size: 5.67vw;
}

@media (min-width: 64rem) {
  .pc__seqk-grid-header h4 {
    font-size: 1.62vw;
  }
}

.pc__seqk-grid-header h5 {
  color: var(--color-dark-grey);
  font-size: 3.325vw;
}

@media (min-width: 64rem) {
  .pc__seqk-grid-header h5 {
    font-size: .95vw;
  }
}

.pc__seqk-grid-header .spacer {
  height: 3.325vw;
}

@media (min-width: 64rem) {
  .pc__seqk-grid-header .spacer {
    height: .95vw;
  }
}

.pc__seqk-grid-image {
  max-width: 80%;
  margin: 0 auto;
}

.pc__seqk-grid-footer {
  justify-content: flex-end;
  display: flex;
}

.pc__seqk-grid-footer .callToaction {
  padding: var(--space-2xs) var(--space-xs);
  font-size: 3.325vw;
}

@media (min-width: 64rem) {
  .pc__seqk-grid-footer .callToaction {
    font-size: .95vw;
  }
}

.pc__seqk-grid-footer .callToaction:hover, .pc__seqk-grid-footer .callToaction:focus-visible {
  background-color: var(--color-light-grey);
  color: var(--color-accent);
}

.drawer {
  z-index: 9;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.drawer__item {
  min-height: 15rem;
  width: 20rem;
  transition: -webkit-transform .3s, transform .3s;
  display: flex;
  -webkit-transform: translateX(90%);
  transform: translateX(90%);
}

.drawer__item:first-child {
  margin-bottom: var(--space-sm);
}

.drawer__header {
  cursor: pointer;
  background-color: #c5c5c5;
  flex-basis: 10%;
  justify-content: center;
  align-items: center;
  transition: background-color .3s;
  display: flex;
  position: relative;
}

.drawer__header:hover, .drawer__header:focus-visible {
  background-color: #000;
}

.drawer__header .icon {
  font-size: 1.5vw;
}

.drawer__panel {
  padding: var(--space-2xs);
  background-color: #fff;
  flex-basis: 90%;
  position: relative;
}

.drawer__panel .icon {
  height: .5vw;
  width: auto;
  margin-bottom: .3em;
}

.drawer__panel p {
  text-transform: uppercase;
  margin-bottom: var(--space-sm);
  font-size: 1.2vw;
}

.drawer__panel .callToaction {
  right: var(--space-xs);
  bottom: var(--space-xs);
  padding: var(--space-2xs) var(--space-xs);
  position: absolute;
}

.drawer__panel-img {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.drawer__item--is-open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.modal {
  z-index: 1000;
  width: 100%;
  height: 100%;
  padding: var(--space-sm) var(--space-md);
  opacity: 0;
  visibility: hidden;
  background-color: #e2e2e2;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 48rem) {
  .modal {
    padding: var(--space-sm) var(--space-lg);
  }
}

@media (min-width: 64rem) {
  .modal {
    padding: var(--space-md);
  }
}

.modal:not(.modal--is-visible) {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
}

.modal.modal--is-visible {
  opacity: 1;
  visibility: visible;
}

.modal__content {
  width: 100%;
  position: relative;
  overflow: auto;
}

.modal__info {
  padding: var(--space-md) 0;
  flex-flow: column;
  display: flex;
}

.modal__info-body {
  padding-bottom: var(--space-md);
}

.modal__info-body h3 {
  margin-bottom: var(--space-sm);
  font-size: 5.8vw;
  font-weight: 600;
}

@media (min-width: 64rem) {
  .modal__info-body h3 {
    margin-bottom: var(--space-lg);
    font-size: 2.9vw;
  }
}

.modal__info-body .textref {
  margin-left: 1.5em;
  font-size: 3.9vw;
}

@media (min-width: 64rem) {
  .modal__info-body .textref {
    font-size: 1.3vw;
  }
}

.modal__info-body .textref .icon {
  font-size: 3.25vw;
}

@media (min-width: 64rem) {
  .modal__info-body .textref .icon {
    font-size: 1.3vw;
  }
}

.modal__info-body .textref span {
  margin-right: var(--space-2xs);
}

.modal__info-list {
  margin-bottom: var(--space-xs);
  padding-left: 1em;
  font-size: 4.55vw;
  list-style-type: disc;
}

@media (min-width: 64rem) {
  .modal__info-list {
    margin-bottom: var(--space-sm);
    margin-bottom: var(--space-lg);
    padding-left: 1.5em;
    font-size: 1.3vw;
  }
}

.modal__info-list li {
  margin-bottom: var(--space-xs);
  font-size: 4.55vw;
  font-weight: 500;
  line-height: 1em;
}

@media (min-width: 64rem) {
  .modal__info-list li {
    font-size: 1.3vw;
  }
}

.modal__info-footer {
  width: 90%;
  justify-content: space-between;
  padding-left: 1.5em;
  font-size: 1.3vw;
  display: flex;
}

@media (min-width: 64rem) {
  .modal__info-footer {
    width: 100%;
    padding-top: var(--space-lg);
  }
}

.modal__info-footer .callToaction {
  font-size: 3.5vw;
}

@media (min-width: 64rem) {
  .modal__info-footer .callToaction {
    font-size: 1vw;
  }
}

.modal__info-footer .icon {
  font-size: 15vw;
}

@media (min-width: 64rem) {
  .modal__info-footer .icon {
    font-size: 5vw;
  }
}

.modal__close-btn--inner {
  cursor: pointer;
  z-index: 15;
  align-items: center;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.modal__close-btn--inner:hover span, .modal__close-btn--inner:focus span, .modal__close-btn--inner:focus-visible span {
  color: var(--color-accent);
}

.modal__close-btn--inner:hover .icon, .modal__close-btn--inner:focus .icon, .modal__close-btn--inner:focus-visible .icon {
  stroke: var(--color-accent);
}

.modal__close-btn--inner span {
  text-transform: uppercase;
  letter-spacing: .1em;
  color: #939393;
  font-size: 3.5vw;
  font-weight: 500;
}

@media (min-width: 64rem) {
  .modal__close-btn--inner span {
    font-size: 1vw;
  }
}

.modal__close-btn--inner .icon {
  stroke: #939393;
  margin-top: -.1em;
  margin-right: .2em;
  font-size: 3.5vw;
}

@media (min-width: 64rem) {
  .modal__close-btn--inner .icon {
    font-size: 1vw;
  }
}

.modal--animate-fade, .modal--animate-scale, .modal--animate-translate-up, .modal--animate-translate-down, .modal--animate-translate-right, .modal--animate-translate-left, .modal--animate-slide-up, .modal--animate-slide-down, .modal--animate-slide-right, .modal--animate-slide-left {
  transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
}

.modal--animate-fade.modal--is-visible, .modal--animate-scale.modal--is-visible, .modal--animate-translate-up.modal--is-visible, .modal--animate-translate-down.modal--is-visible, .modal--animate-translate-right.modal--is-visible, .modal--animate-translate-left.modal--is-visible, .modal--animate-slide-up.modal--is-visible, .modal--animate-slide-down.modal--is-visible, .modal--animate-slide-right.modal--is-visible, .modal--animate-slide-left.modal--is-visible {
  transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s;
}

.modal--animate-fade.modal--is-visible .modal__content, .modal--animate-scale.modal--is-visible .modal__content, .modal--animate-translate-up.modal--is-visible .modal__content, .modal--animate-translate-down.modal--is-visible .modal__content, .modal--animate-translate-right.modal--is-visible .modal__content, .modal--animate-translate-left.modal--is-visible .modal__content, .modal--animate-slide-up.modal--is-visible .modal__content, .modal--animate-slide-down.modal--is-visible .modal__content, .modal--animate-slide-right.modal--is-visible .modal__content, .modal--animate-slide-left.modal--is-visible .modal__content {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.modal--animate-scale .modal__content, .modal--animate-translate-up .modal__content, .modal--animate-translate-down .modal__content, .modal--animate-translate-right .modal__content, .modal--animate-translate-left .modal__content, .modal--animate-slide-up .modal__content, .modal--animate-slide-down .modal__content, .modal--animate-slide-right .modal__content, .modal--animate-slide-left .modal__content, .modal--animate-slide-up.modal--is-visible .modal__content, .modal--animate-slide-down.modal--is-visible .modal__content, .modal--animate-slide-right.modal--is-visible .modal__content, .modal--animate-slide-left.modal--is-visible .modal__content {
  will-change: transform;
  transition: -webkit-transform var(--modal-transition-duration) var(--ease-out);
  transition: transform var(--modal-transition-duration) var(--ease-out);
  transition: transform var(--modal-transition-duration) var(--ease-out), -webkit-transform var(--modal-transition-duration) var(--ease-out);
}

.modal--animate-scale .modal__content {
  -webkit-transform: scale(.95);
  transform: scale(.95);
}

.modal--animate-translate-up .modal__content {
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
}

.modal--animate-translate-down .modal__content {
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
}

.modal--animate-translate-left .modal__content {
  -webkit-transform: translateX(-40px);
  transform: translateX(-40px);
}

.modal--animate-slide-up .modal__content {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.modal--animate-slide-down .modal__content {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.modal--animate-slide-right .modal__content {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.modal--animate-slide-left .modal__content {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.modal--is-loading .modal__content {
  visibility: hidden;
}

.modal--is-loading .modal__loader {
  display: flex;
}

.modal__loader {
  width: 100%;
  height: 100%;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.modal__loader .icon {
  color: var(--color-green);
  font-size: 48px;
}

.modal__loader .icon.icon--is-spinning {
  -webkit-animation: 1s linear infinite icon-spin;
  animation: 1s linear infinite icon-spin;
}

:root {
  --morph-img-modal-transition-duration: .35s;
}

.morph-img-modal.modal {
  --modal-transition-duration: var(--morph-img-modal-transition-duration);
}

.morph-img-modal:before {
  content: "mobile";
  display: none;
}

.morph-img-modal__content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.morph-img-modal__info {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  transition: opacity .2s;
  overflow: auto;
}

.morph-img-bg, .morph-img-clone {
  z-index: var(--z-index-overlay, 15);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  position: fixed;
}

.morph-img-clone {
  overflow: hidden;
}

.morph-img-clone svg, .morph-img-clone rect, .morph-img-clone image {
  height: 100%;
  width: 100%;
}

.morph-img-close-btn-wrapper {
  width: 100%;
  background: none;
  position: fixed;
  top: 0;
  left: 0;
}

.morph-img-close-btn {
  top: var(--space-sm);
  left: var(--space-sm);
  cursor: pointer;
  width: 48px;
  height: 48px;
  z-index: 1002;
  opacity: 0;
  visibility: hidden;
  transition: background .2s, opacity var(--morph-img-modal-transition-duration), visibility 0s var(--morph-img-modal-transition-duration);
  background-color: pink;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
}

.morph-img-close-btn:hover {
  background-color: green;
}

.morph-img-close-btn .icon {
  color: var(--color-white);
  margin: auto;
  display: block;
}

.morph-img-close-btn--is-visible {
  opacity: 1;
  visibility: visible;
  transition: background .2s, opacity var(--morph-img-modal-transition-duration);
}

@media (min-width: 64rem) {
  .morph-img-modal {
    padding: 0 var(--space-md);
  }

  .morph-img-modal:before {
    content: "dekstop";
  }

  .morph-img-modal__content {
    max-height: calc(100% - 96px - var(--space-md) * 2);
    width: 100%;
    max-width: 80rem;
    height: auto;
    display: flex;
    overflow: auto;
  }

  .morph-img-modal__figure-wrapper {
    flex-basis: 50%;
  }

  .morph-img-modal__info {
    flex-basis: 50%;
    overflow: hidden;
  }
}

/*# sourceMappingURL=index.95db0491.css.map */
