
:root {
    --morph-img-modal-transition-duration: .35s; // expanding animation duration
}

.morph-img-modal {
    &.modal {
      --modal-transition-duration: var(--morph-img-modal-transition-duration);
    }
    
    // never visible -  used in JS to check if we need to run the morphing animation
    &::before {
      display: none;
      content: 'mobile';
    }
  }
  
  .morph-img-modal__content {
    width: 100%;
    overflow: auto;
    height: 100%;
  }
  
  .morph-img-modal__info {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    transition: opacity .2s;
  }
  
  // morphing image/bg layer
  .morph-img-bg, .morph-img-clone {
    position: fixed;
    z-index: var(--z-index-overlay, 15); 
    transform-origin: left top;
    backface-visibility: hidden;
    will-change: transform;
  }
  
  .morph-img-clone {
    overflow: hidden;
  
    svg, rect, image {
      height: 100%;
      width: 100%;
    }
  }
  
  .morph-img-close-btn-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: none;
    // z-index: -1;
    // pointer-events: none;
  }
  // close btn
  .morph-img-close-btn {
    // position: fixed;
    top: var(--space-sm);
    left: var(--space-sm);
    cursor: pointer;
    width: 48px;
    height: 48px;
    z-index: 1002;
    border-radius: 50%;
    background-color: pink;
    
    flex-shrink: 0;
    display: flex;
  
    opacity: 0;
    visibility: hidden;
  
    transition: background .2s, opacity var(--morph-img-modal-transition-duration), visibility 0s var(--morph-img-modal-transition-duration);
  
    &:hover {
      background-color: green;
    }
  
    .icon {
      color: var(--color-white); // icon color
      display: block;
      margin: auto;
    }
  }
  
  .morph-img-close-btn--is-visible {
    opacity: 1;
    visibility: visible;
    transition: background .2s, opacity var(--morph-img-modal-transition-duration);
  }
  
  @include breakpoint(md) {
    .morph-img-modal {
      padding: 0 var(--space-md);
  
      &::before {
        content: 'dekstop';
      }
    }
  
    .morph-img-modal__content {
      overflow: hidden;
      display: flex;
      max-height: calc(100% - 96px - var(--space-md) * 2);
      width: 100%;
      overflow: auto;
      max-width: 80rem;
      height: auto;
    }
    .morph-img-modal__figure-wrapper{
      flex-basis: 50%;
    }
    .morph-img-modal__info{
      flex-basis: 50%;
      overflow: hidden;
    }

  }