.pc

    &__seqg
        position: relative
        padding: var(--space-2xl) var(--space-md) 0 var(--space-md)

    &__seqg-content
        text-align: center
        position: relative
        width: 100%

        .grid
            padding-top: var(--space-md)
            
    &__seqg-image-container
        position: relative
        grid-column: 1/13
        height: 0
        padding-bottom: 80%
        order: 2
        margin-left: calc(var(--space-md) * -1)
        margin-right: calc(var(--space-md) * -1)
        @include breakpoint(md)
            grid-column: 3/7
            height: auto
            padding-bottom: 0
            order: 1
            margin-left: 0
            margin-right: 0

    &__seqg-image
        position: absolute
        top: -20%
        left: 0
        width: 100%
        height: 0
        padding-bottom: 100%

        @include breakpoint(md)
            top: -60%
            
    &__seqg-details
        text-align: left
        grid-column: 1/13
        order: 1
        margin-bottom: var(--space-lg)

        @include breakpoint(md)
            grid-column: 7/13
            order: 2
            
        li
            position: absolute
            width: 100%
            
            @include breakpoint(md)
                position: relative
                margin-bottom: var(--space-sm)

            p
                text-align: center
                color: var(--color-text-black)
                transition: color 0.3s

                @include breakpoint(md)
                    color: var(--color-dark-grey)

                @include breakpoint(md)
                    text-align: left
                
                &.active-point
                    color: var(--color-text-black)
