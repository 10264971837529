.header
    position: fixed
    left: 0
    bottom: 0
    z-index: 10
    width: 100%
    background-color: var(--header-color-bg)
    height: calc(1vw + var(--space-sm) + 1.5vw)
    transition: background-color 0.3s

    &__content
        position: relative
        display: flex
        justify-content: center
        align-items: center
        height: 100%

        .box
            
            display: inline-block
            margin-right: 0.5em
            height: 10px
            @include breakpoint(md)
                height: 15px
                margin-right: 1em

            svg
                display: block
                height: 100%
                width: auto!important
        
        svg
            height: 100%
            width: auto

        span
            color: var(--header-color)
            text-transform: uppercase
            font-size: 15px
            font-weight: 400
            display: inline-block
            line-height: 1em
            @include breakpoint(md)
                font-size: 20px

        .callToaction
            position: absolute
            top: 50%
            right: 0
            display: none
            transform: translate(50%, -50%)
            -webkit-transform: translate(50%, -50%)

            @include breakpoint(md)
                display: block

.side
    position: fixed
    top: 0
    right: 0
    z-index: 10
    display: none
    @include breakpoint(sm)
        display: block

    &__list
        padding: var(--space-md)

        li
            margin-bottom: var(--space-xxs)
            text-align: right

            span
                display: inline-block
            
    &__link
        position: relative
        text-transform: uppercase
        font-weight: 600
        color: #939393
        text-decoration: none
        font-size: 1vw
        padding-right: 1.2vw

        &.slide__link--selected

            &::after
                background-color: #939393

        &::before,    
        &::after
            content: ''
            position: absolute
        
        &::before
            width: 0
            height: 2px
            background-color: #939393
            bottom: 0
            left: 0
            transition: width 0.3s

        &::after
            top: 0
            right: 0
            width: 0.8vw
            height: 0.8vw
            border-radius: 50%
            border-width: 2px
            border-style: solid
            border-color: #939393
            background-color: none
            transition: background-color 0.3s

        &:hover
            &::before
                width: calc( 100% - 1.2vw )

            &::after
                background-color: #939393