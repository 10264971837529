.pc
    &__seqk
        position: relative
        display: flex
        justify-content: center
        padding: var(--space-md) var(--space-sm)
        @include breakpoint(md)
            padding: var(--space-lg) var(--space-md)

    &__seqk-content
        position: relative
        width: 100%
        text-align: center

        .icon
            height: calc(2vw * 2)
            width: auto
            margin:0 auto var(--space-sm) auto
            @include breakpoint(md)
                height: 2vw
                margin:0 auto var(--space-lg) auto


    &__seqk-grid
        max-width: 1800px
        margin-left: auto
        margin-right: auto
        grid-template-columns: repeat(1, 1fr)
        column-gap: 1vw
        row-gap: var(--space-sm)
        margin-bottom: calc(1vw + var(--space-sm) + 1.5vw)
        padding-top: var(--space-lg)
        @include breakpoint(md)
            padding-top: calc(var(--space-lg) *2)
            grid-template-columns: repeat(2, 1fr)
        @include breakpoint(lg)
            grid-template-columns: repeat(4, 1fr)
            row-gap: 0

    &__seqk-grid-col
        position: relative
        background: #fff
        text-align: left
        padding: var(--space-sm)

        
    
    &__seqk-grid-header
        
        .icon
            height: calc( 0.65vw * 3.5 )
            width: auto
            margin: 0
            @include breakpoint(md)
                height: 0.65vw

        h4
            padding-top: calc(var(--space-unit)*0.5)
            font-size: calc( 1.62vw * 3.5 )
            @include breakpoint(md)
                font-size: 1.62vw

        h5
            font-size: calc( 0.95vw * 3.5 )
            color: var(--color-dark-grey)
            @include breakpoint(md)
                font-size: 0.95vw

        .spacer
            height: calc( 0.95vw * 3.5 )
            @include breakpoint(md)
                height: 0.95vw

    &__seqk-grid-image
        max-width: 80%
        margin: 0 auto
        

    &__seqk-grid-footer
        display: flex
        justify-content: flex-end

        .callToaction
            font-size: calc( 0.95vw * 3.5 )
            padding: var(--space-2xs) var(--space-xs)
            @include breakpoint(md)
                font-size: 0.95vw

            &:hover,
            &:focus-visible
                background-color: var(--color-light-grey)
                color: var(--color-accent)