h1
    font-size: 2vw
    line-height: 1em

h2
    font-size: calc(5.4vw * 1.8)
    line-height: 1em
    font-weight: 600
    @include breakpoint(md)
        font-size: 5.4vw

h3
    font-size: calc(2.5vw * 1.8)
    line-height: 1em
    font-weight: 500
    letter-spacing: 0.1em
    @include breakpoint(md)
        font-size: 2.5vw

h4, h5
    font-size: calc(0.9vw * 2.5)
    line-height: 1em
    text-transform: uppercase
    font-weight: 600

    @include breakpoint(md)
        font-size: 0.9vw

h5
    letter-spacing: 0.08em

p
    font-size: calc(2vw * 2.5)
    font-weight: 500

    @include breakpoint(md)
        font-size: 2vw

.xs
    font-size: calc(0.65vw * 2.5)
    line-height: 1.2em
    font-weight: 400
    @include breakpoint(md)
        font-size: 0.65vw

.book
    font-weight: 400

.bold
    font-weight: 700

.inlineblock
    display: inline-block

h1, h2, h3
    text-transform: uppercase

.callToaction
    display: inline-flex
    background-color: var(--color-accent)
    text-decoration: none
    text-transform: uppercase
    text-align: center
    padding: var(--space-2xs) var(--space-sm)
    font-size: calc(0.8vw * 2.5)
    line-height: 1em
    color: #fff
    font-weight: 600
    letter-spacing: 0.05em
    transition: box-shadow 0.2s, background-color 0.3s, color 0.3s
    @include breakpoint(md)
        font-size: 0.8vw

    &:hover,
    &:focus-visible
        background-color: #fff
        color: var(--color-accent)

.textref
    display: inline-flex
    position: relative
    color: #939393
    line-height: 1em
    font-weight: 500
    text-decoration: none
    transition: color 0.2s

    .icon
        stroke: #939393
        transition: stroke 0.2s
        animation: arrow-pulse 2s infinite


    span
        position: relative
        display: inline-flex

        &:after
            content: ''
            position: absolute
            bottom: -0.2em
            left: 0
            width: 100%
            height: 0.1em
            background-color: #939393
            transition: background-color 0.1s, height 0.2s


    &:hover,
    &:focus-visible
        color: #fff

        .icon
            stroke: #fff

        span
            &:after
                background-color: #fff


.v-hidden
    visibility: hidden

.inlineblock
    display: inline-block

canvas
    display: block

.canvas
    display: block
    position: relative
    z-index: 0
    


    &__buttons
        position: absolute
        left: 0
        bottom: 0
        z-index: 1
        padding: var(--space-xs)

    &__btn
        background-color: var(--color-accent)
        cursor: pointer
        display: inline-flex
        justify-content: center
        line-height: 1.2
        padding: var(--space-xxs) var(--space-xs)
        border-radius: var(--radius-md)
        white-space: nowrap
        color: #fff
        border: none

        &.active
            background-color: var(--color-accent-light)
    
.svg-fill-grey
    fill: #939393

.svg-stroke-grey
    stroke: #939393