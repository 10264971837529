.pc
    &__seqc
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        text-align: center

        h2, h3
            color: #fff            

        h3
            margin-bottom: var(--space-sm)
            @include breakpoint(md)
                margin-bottom: var(--space-md)

    &__seqc-h2-wrapper
        @include breakpoint(md)
            transform: translateX(3em)


    &__seqc-span-container
        position: relative
        display: inline-flex
        flex-direction: column
    
    &__seqc-t-c,
    &__seqc-t-d,
    &__seqc-t-e
        width: auto
        @include breakpoint(md)
            display: inline-flex

        &.ready
            span
                &:after
                    width: 100%

        span
            position: relative
            display: inline-block
        
            &:after
                content: ''
                position: absolute
                left: 0
                bottom: -0.05em
                height: 0.05em
                width: 0
                background-color: #fff
                transition: width 0.5s var(--ease-out)