.pc
    
    &__seqa,
    &__seqb
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: calc(100vh - (1vw + var(--space-sm) + 1.5vw))
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        text-align: center

        h3
            margin-top: var(--space-md)
            @include breakpoint(md)
                margin-top: var(--space-sm)

    &__seqa
        .icon
            margin-bottom: var(--space-md)
            height: calc(2vw * 1.8)
            width: auto
            color: var(--color-text-black)
            @include breakpoint(md)
                height: 2vw
                margin-bottom: var(--space-lg)

    &__seqb
        color: #fff
