.pc

    &__seqf
        position: relative
        display: flex
        justify-content: center

    &__seqf
        align-items: center
        color: #fff

    &__seqf-video-container
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        overflow: hidden
        z-index: -1
        @media (prefers-reduced-motion: reduce)
            video
                visibility: hidden
            img
                display: block
                position: absolute
                object-fit: cover
                height: 100%
                width: 100%

        video
            position: relative
            top: 50%
            left: 50%
            transform: translateX(-50%) translateY(-50%)
            min-height: 100%
            min-width: 100%
            height: auto
            width: auto
            max-width: none
            display: none
            
            
            @supports(object-fit: cover)
                object-fit: cover
                height: 100%
                width: 100%

            @supports (object-fit: cover) and (-ms-ime-align:auto)
                height: auto
                width: auto


        img
            display: block
            position: absolute
            object-fit: cover
            height: 100%
            width: 100%

    &__seqf-video
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        

    &__seqf-content
        position: relative
        width: 100%
        
        h2
            text-align: center
            margin-bottom: var(--space-lg)

        p
            text-align: center 
            margin-bottom: var(--space-lg)
            @include breakpoint(md)
                max-width: 35vw
                text-align: left
                margin-bottom: calc(var(--space-lg)*3)

        .textref
            font-size: calc(2vw * 2)
            text-align: center
            @include breakpoint(md)
                font-size: 2vw
            
            .icon
                font-size: calc(2vw * 2)
                @include breakpoint(md)
                    font-size: 2vw
            span
                
                @include breakpoint(md)
                    margin-right: var(--space-xs)

        .max-md
            display: flex
            justify-content: flex-end


@keyframes arrow-pulse
    0%
        transform: translateX(0)
    
    50% 
        transform: translateX(1rem)

    100% 
        transform: translateX(0rem)