html
    scroll-behavior: smooth

    &.cursor-pointer
        cursor: pointer

body
    font-family: 'Futura'
    font-weight: 400
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    text-rendering: optimizeLegibility
    background-color: var(--page-bg)
    color: var(--page-color)
    transition: background-color 0.3s, color 0.3s

::-moz-selection
    background: var(--color-accent-light)

::selection
    background: var(--color-accent-light)

button
    background-color: transparent
    font-weight: 700
    padding: 0
    border: 0
    border-radius: 0
    font-family: 'Futura'
    color: inherit
    line-height: inherit
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    
// Page Container
.container
    position: relative
    width: calc(100% - 2*var(--component-padding))
    margin-left: auto
    margin-right: auto

.max-sm
    max-width: 768px
    
.max-md
    max-width: 1024px

.max-lg
    max-width: 1200px

.sr-only
    position: absolute
    clip-path: rect(1px,1px,1px,1px)
    -webkit-clip-path: inset(50%)
    width: 1px
    height: 1px
    overflow: hidden
    padding: 0
    border: 0
    white-space: nowrap

.icon
    display: block
    color: inherit
    fill: currentColor
    height: 1em
    width: 1em
    line-height: 1
    flex-shrink: 0
    max-width: initial


// IMAGES
img
    position: relative
    width: 100%
    height: auto


.loadImg
    opacity: 0
    transition: opacity 0.3s
    &.loaded
        opacity: 1


// BodyLoading
.loading::before,
.loading::after
    content: ''
    position: fixed
    z-index: 9999
    cursor: none

.loading::before
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: var(--color-grey)

.loading::after
    top: 50%
    left: 50%
    width: 100px
    height: 100px
    pointer-events: none
    margin: -50px 0 0 -50px
    border-radius: 50%
    opacity: 0.4
    background: var(--color-accent)
    -webkit-animation: loaderAnim 0.7s linear infinite alternate forwards
    animation: loaderAnim 0.7s linear infinite alternate forwards

@keyframes loaderAnim
    to
        opacity: .7
        -webkit-transform: scale3d(0.5,0.5,1)
        -ms-transform: scale3d(0.5,0.5,1)
        transform: scale3d(0.5,0.5,1)