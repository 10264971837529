.pc

    &__seqd
        position: relative
        padding: var(--space-sm) var(--space-sm)
        display: flex
        align-items: center
        

        @include breakpoint(md)
            display: block
            padding: var(--space-lg) var(--space-md)

    &__seqd-content
        text-align: center
        position: relative
        width: 100%
        margin-top: calc( ( 1vw + var(--space-sm) + 1.5vw ) * -1)

        @include breakpoint(md)
            margin-top: 0

        h2
            z-index: 2

        .grid
            padding-top: var(--space-sm)
            
            @include breakpoint(md)
                padding-top: var(--space-md)
    
    &__seqd-image
        padding-top: var(--space-md)
        position: relative
        grid-column: 3/11
        margin-bottom: var(--space-sm)
        @include breakpoint(sm)
            grid-column: 3/11

    &__seqd-details
        text-align: left
        grid-column: 1/13
        

        @include breakpoint(md)
            grid-column: 7/13
            
        li
            position: absolute
            width: 100%
            
            @include breakpoint(md)
                position: relative
                margin-bottom: var(--space-sm)

            p
                text-align: center
                color: #fff
                transition: color 0.3s

                @include breakpoint(md)
                    color: var(--color-dark-grey)

                @include breakpoint(md)
                    text-align: left
                
                &.active-point
                    color: #fff

    &__seqd-canvas
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        z-index: -1