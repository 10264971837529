.drawer
    position: absolute    
    top: 50%
    right: 0
    transform: translateY(-50%)
    -webkit-transform: translateY(-50%)
    z-index: 9

    &__item
        display: flex
        min-height: 15rem
        width: 20rem
        transform: translateX(90%)
        -webkit-transform: translateX(90%)
        transition: transform 0.3s ease
        &:first-child
            margin-bottom: var(--space-sm)

    &__header
        position: relative
        flex-basis: 10%
        background-color: #c5c5c5
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        transition: background-color 0.3s 

        &:hover,
        &:focus-visible
            background-color: #000
            
            
        .icon
            font-size: 1.5vw


            
    &__panel
        position: relative
        background-color: #fff
        flex-basis: 90%
        padding: var(--space-2xs)
        
        .icon
            height: 0.5vw
            width: auto
            margin-bottom: 0.3em
                
        p
            font-size: 1.2vw
            text-transform: uppercase
            margin-bottom: var(--space-sm)

        .callToaction
            position: absolute
            right: var(--space-xs)
            bottom: var(--space-xs)
            padding: var(--space-2xs) var(--space-xs)


    &__panel-img
        width: 75%
        margin-left: auto
        margin-right: auto


.drawer__item--is-open
    transform: translateX(0)
    -webkit-transform: translateX(0)