@font-face {
    font-family: 'Futura';
    src: url("../../src/fonts/futura-bold.woff2")  format("woff2");
         font-style: normal;
         font-weight: 700;
         text-rendering: optimizeLegibility;
         font-display: swap;
}

@font-face {
    font-family: 'Futura';
    src: url("../../src/fonts/Futura-Dem.otf")  format("opentype");
         font-style: normal;
         font-weight: 600;
        //  text-rendering: optimizeLegibility;
        //  font-display: swap;
}
@font-face {
    font-family: 'Futura';
    src: url("../../src/fonts/Futura-Med.otf")  format("opentype");
         font-style: normal;
         font-weight: 500;
         text-rendering: optimizeLegibility;
         font-display: swap;
}
@font-face {
    font-family: 'Futura';
    src: url("../../src/fonts/futura-book.woff2")  format("woff2");
         font-style: normal;
         font-weight: 400;
         text-rendering: optimizeLegibility;
         font-display: swap;
}