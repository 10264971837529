.pc

    &__seqe
        position: relative
        padding: var(--space-lg) var(--space-md) 0 var(--space-md)

    &__seqe-content
        text-align: center
        position: relative
        width: 100%
    
        .grid
            padding-top: var(--space-md)
            margin-left: calc(var(--space-md) * -1)
            margin-right: calc(var(--space-md) * -1)
            @include breakpoint(md)
                margin-left: 0
                margin-right: 0
            
    &__seqe-image-mobile
        grid-column: 5/13
        margin-top: -40%
        order: 3

    &__seqe-image
        position: relative
        grid-column: 1/10
        z-index: -1
        order: 2
        padding-top: var(--space-lg)
        
        
        @include breakpoint(md)
            grid-column: 3/6
            order: 1

    &__seqe-image-container
        width: 100%
        
        @include breakpoint(md)
            position: absolute
            top: 0
            left: 0
        
        img
            width: 100%
            height: 100%
            object-fit: cover

    &__seqe-details
        position: relative
        text-align: left
        grid-column: 1/13
        order: 1
        @include breakpoint(md)
            grid-column: 7/13
            padding-top: 0
            order: 2

        li
            position: absolute
            width: 100%
            @include breakpoint(md)
                position: relative
                margin-bottom: var(--space-sm)

            p
                text-align: center
                color: #fff
                transition: color 0.3s

                @include breakpoint(md)
                    color: var(--color-dark-grey)

                @include breakpoint(md)
                    text-align: left
                
                &.active-point
                    color: #fff

    &__seqe-canvas
        grid-column: 5/9
        overflow: hidden
        display: none
        justify-content: center
        overflow: hidden
        @include breakpoint(md)
            display: flex

    
        